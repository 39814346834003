import { useSelector } from 'react-redux';

import './app.css';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// i18n lang translator
import './i18n';

// project imports
import ToastContainer from 'ui-component/toast';
import ScrollToTop from 'ui-component/ScrollToTop';
import NavigationScroll from 'layout/NavigationScroll';

// ⚛️ ============ ⚛️ App ⚛️ ============ ⚛️ /
const App = () => {
    const customization = useSelector((state) => state.customization);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                    <Box sx={{ position: 'fixed', bottom: 0, left: 0, bgcolor: 'white', zIndex: 10000 }}>Powered By SSBD PVT LTD.</Box>
                </NavigationScroll>
                <ScrollToTop />
                <ToastContainer />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
