import { forwardRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

import {
    IconGauge,
    IconSearch,
    IconUsers,
    IconFilePlus,
    IconClipboard,
    IconDeviceTabletSearch,
    IconUserCog,
    IconSquarePlus2,
    IconPlaylistAdd,
    IconHelpHexagon,
    IconReport,
    IconChecklist,
    IconFileDescription,
    IconFilePencil,
    IconProgressCheck,
    IconRosetteDiscountCheckFilled,
    IconFileVector,
    IconMessageChatbot,
    IconCodeAsterisk
} from '@tabler/icons-react';
import { IconVersions, IconFileCheck, IconFileInfo, IconUserPlus, IconUserSearch, IconPointFilled } from '@tabler/icons-react';
import { IconFileFilled, IconTimeline, IconPackageExport, IconReportSearch, IconQrcode, IconBrandAndroid } from '@tabler/icons-react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const Icons = {
        IconGauge,
        IconSearch,
        IconUsers,
        IconFilePlus,
        IconClipboard,
        IconDeviceTabletSearch,
        IconUserCog,
        IconSquarePlus2,
        IconBrandAndroid,
        IconPlaylistAdd,
        IconHelpHexagon,
        IconReport,
        IconChecklist,
        IconFileDescription,
        IconFilePencil,
        IconVersions,
        IconFileCheck,
        IconFileInfo,
        IconUserPlus,
        IconUserSearch,
        IconPointFilled,
        IconFileFilled,
        IconTimeline,
        IconPackageExport,
        IconReportSearch,
        IconProgressCheck,
        IconRosetteDiscountCheckFilled,
        IconFileVector,
        IconMessageChatbot,
        IconQrcode,
        IconCodeAsterisk
    };

    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = Icons[item.icon] || item.icon || IconPointFilled;
    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} />)
    };

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
    }, []);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: '2px',
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                '&.Mui-selected': {
                    backgroundColor: '#485468 !important'
                },
                '&:hover': {
                    backgroundColor: '#485468 !important'
                },
                pt: 1,
                color: 'white',
                pl: level > 1 ? 2 : 'inherit',
                ml: level > 1 ? 2 : 'inherit'
            }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: 36, color: '#bababa' }}>
                <Icon stroke={2} size="1.4rem" style={{ color: '#FFFFFF' }} />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                        style={{ color: '#FFFFFF' }}
                    >
                        {t(item.title)}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default NavItem;
