import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import { Outlet } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';

// login option 3 routing
const LoginViewUser = Loadable(lazy(() => import('views/viewUser/Login')));

// login option 3 routing
const MenuPage = Loadable(lazy(() => import('pages/authentication')));
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const FSLLogin = Loadable(lazy(() => import('pages/authentication/FSLLogin')));
const NCBLogin = Loadable(lazy(() => import('pages/authentication/NCBLogin')));
const RegisterDevice = Loadable(lazy(() => import('pages/authentication/RegisterDivece')));
// const Dashboard = Loadable(lazy(() => import('components/DashboardCharts/index3')));
// const Dashboard = Loadable(lazy(() => import('components/DashboardCharts/index2')));
const Dashboard = Loadable(lazy(() => import('components/DashboardCharts')));
const Dashboard3 = Loadable(lazy(() => import('components/DashboardCharts/StaticDash')));
const Dashboard2 = Loadable(lazy(() => import('components/DashboardCharts/indexDas2')));
const Loading = Loadable(lazy(() => import('Loading')));
const PreviewFile = Loadable(lazy(() => import('utils/FilePreviewAnd')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <Outlet />,
    children: [
        { path: '/', element: <MenuPage /> },
        { path: '/registerDevice', element: <RegisterDevice /> },
        { path: '/policeLogin', element: <Login type="Police" /> },
        { path: '/ministerLogin', element: <Login type="CM" /> },
        { path: '/judicialLogin', element: <Login type="Judicial" /> },
        { path: '/forensicLogin', element: <Login type="CM" /> },
        // { path: '/fslLogin', element: <FSLLogin /> },
        { path: '/ncbLogin', element: <NCBLogin /> },
        { path: '/loginViewUser', element: <LoginViewUser /> },
        { path: '/file/preview/:fileName', element: <PreviewFile /> },
        { path: '/file/preview/:fileName/:typeFile', element: <PreviewFile /> },
        {
            path: '/dashboard2',
            element: (
                <div style={{ padding: '20px' }}>
                    <Outlet />
                </div>
            ),
            children: [{ path: '/dashboard2', element: <Dashboard2 /> }]
        },
        { path: '/dashboard', element: <MainLayout />, children: [{ path: '/dashboard', element: <Dashboard type="main" /> }] },
        { path: '/dashboard3', element: <MainLayout />, children: [{ path: '/dashboard3', element: <Dashboard3 type="main" /> }] }
        // { path: '/', element: <AuthLogin />}
        // { path: '/:token/:username/:firstname/:lastname/:role/:spOffice', element: <Loading />},
        // { path: '/:token/:username/:firstname/:lastname/:role/:policeStation/:spOffice', element: <Loading />}
    ]
};

export default AuthenticationRoutes;
