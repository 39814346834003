import { useState, useRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Tooltip,
    Typography
} from '@mui/material';

// third-party
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

// assets
import { IconKey, IconLogout, IconSettings, IconUser, IconHelp } from '@tabler/icons-react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { postMethodFun } from 'utils/Apirequests';
import Transitions from 'ui-component/extended/Transitions';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const anchorRef = useRef(null);

    let role = sessionStorage.getItem('role')?.toUpperCase() === 'SO' ? 'SP' : sessionStorage.getItem('role');

    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(-1);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = async (event, index, route = '') => {
        setSelected(index);
        handleClose(event);
        if (route && route == '/dashboard') {
            navigate(`/${role}`);
        } else if (route && route == '/') {
            const endTime = Date.now();
            let token = sessionStorage.getItem('token');
            try {
                await postMethodFun({ endTime, token }, 'logout');
            } catch (error) {
                console.warn('Error: ' + error.message);
            }
            sessionStorage.clear();
            if (role.toLowerCase() === 'malkhanaofficer' || role.toLowerCase() === 'seizureofficer') {
                navigate('/ncbLogin');
            } else {
                navigate(route);
            }
        } else if (route && route !== '') {
            navigate(route);
        }
    };

    let firstname = sessionStorage.getItem('firstname');
    let lastname = sessionStorage.getItem('lastname');
    let username = sessionStorage.getItem('username');
    let userid = sessionStorage.getItem('userid');

    const styleButton = {
        borderRadius: `${customization.borderRadius}px`,
        m: '2px 10px',
        alignItems: 'flex-start',
        '&.Mui-selected, &:hover': { backgroundColor: '#E0E7F0 !important' }
    };

    return (
        <>
            <Typography sx={{ color: 'white', mx: 2 }}>|</Typography>

            {role !== 'MINISTER' ? (
                <Typography variant="h4" sx={{ color: 'white', mr: 2 }}>
                    {username}
                </Typography>
            ) : (
                <Typography variant="h4" sx={{ color: 'white', mr: 2 }}>
                    {userid}
                </Typography>
            )}
            <Tooltip title="Profile">
                <Avatar
                    sx={{
                        height: '33px',
                        width: '33px',
                        fontWeight: 500,
                        transition: 'all .2s ease-in-out',
                        backgroundColor: '#E0E7F0',
                        cursor: 'pointer',
                        color: '#253659',
                        '&[aria-controls="menu-list-grow"], &:hover': {
                            borderColor: '#C0C7D0',
                            background: `#C0C7D0 !important`,
                            '& svg': { stroke: '#253659' }
                        }
                    }}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={() => setOpen((prev) => !prev)}
                    ref={anchorRef}
                >
                    {firstname[0].toUpperCase()}
                    {/* {firstname?.[0]?.toUpperCase()} */}
                </Avatar>
            </Tooltip>

            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [0, 14] } }] }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Typography variant="h4" sx={{ fontWeight: 650, padding: '20px 0 0 20px' }}>
                                        {firstname?.toUpperCase()} {lastname?.toUpperCase()}
                                    </Typography>

                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: { minWidth: '100%' }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={styleButton}
                                                    selected={selected === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/dashboard')}
                                                >
                                                    <ListItemIcon sx={{ '&.MuiListItemIcon-root': { padding: '3px' } }}>
                                                        <IconSettings stroke={2} size="1.3rem" color="#253659" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography color="#253659">{t('Dashboard')}</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={styleButton}
                                                    onClick={(event) => handleListItemClick(event, 1, '/profile')}
                                                >
                                                    <ListItemIcon sx={{ '&.MuiListItemIcon-root': { padding: '3px' } }}>
                                                        <IconUser stroke={2} size="1.3rem" color="#253659" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography color="#253659">{t('User Profile')}</Typography>} />
                                                </ListItemButton>
                                                {role == 'SP' || role == 'IO' || role == 'PI' ? (
                                                    <ListItemButton
                                                        sx={styleButton}
                                                        selected={selected === 1}
                                                        onClick={(event) => handleListItemClick(event, 1, 'changePassword')}
                                                    >
                                                        <ListItemIcon sx={{ '&.MuiListItemIcon-root': { padding: '3px' } }}>
                                                            <IconKey stroke={2} size="1.3rem" color="#253659" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={<Typography color="#253659">{t('Change Password')}</Typography>}
                                                        />
                                                    </ListItemButton>
                                                ) : null}
                                                <ListItemButton
                                                    sx={styleButton}
                                                    to="http://13.200.87.56:8000/support-ticket"
                                                    target="_blanck"
                                                    component="a"
                                                >
                                                    <ListItemIcon sx={{ '&.MuiListItemIcon-root': { padding: '3px' } }}>
                                                        <IconHelp stroke={2} size="1.3rem" color="#253659" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography color="#253659">{t('Support')}</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton sx={styleButton} onClick={(event) => handleListItemClick(event, 3, '/')}>
                                                    <ListItemIcon sx={{ '&.MuiListItemIcon-root': { padding: '3px' } }}>
                                                        <IconLogout stroke={2} size="1.3rem" color="#253659" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography color="#253659">{t('Logout')}</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
