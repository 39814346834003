import axios from 'axios';

const URL = process.env.REACT_APP_SERVER_URL;
const URL_Test = process.env.REACT_APP_SERVER_URL_TEST;
const mediaURL = process.env.REACT_APP_MEDIA_URL;
const PyURL = process.env.REACT_APP_PY_URL;

const getMediaURL = `${mediaURL}/getFiles`;
const postMediaURL = `${mediaURL}/uploadFile`;
const previewMediaURL = `${mediaURL}/preview`;

export const getMethodFun = async (endPoint) =>
    await axios.get(`${sessionStorage.getItem('isTest') !== 'Yes' ? URL : URL_Test}/${endPoint}`);

export const postMethodFun = async (formData, endPoint, config = {}) =>
    await axios.post(`${sessionStorage.getItem('isTest') !== 'Yes' ? URL : URL_Test}/${endPoint}`, formData, config);
export const postMethodFun2 = async (formData, endPoint) =>
    await axios.post(`${sessionStorage.getItem('isTest') !== 'Yes' ? URL : URL_Test}/${endPoint}`, formData, { transformResponse: null });

export const mediaUpload = async (formData, progress) => {
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (data) => {
            progress(data.progress * 100);
        }
    };
    let response = await axios.post(postMediaURL, formData, config);
    return response;
};

export const getMethodPy = async (endPoint) => await axios.get(`${PyURL}/${endPoint}`);

export const postMediaPy = async (endPoint, formData, progress) => {
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (data) => {
            progress(data.progress * 100);
        }
    };
    let response = await axios.post(`${PyURL}/${endPoint}`, formData, config);
    return response;
};

const MediaURL = { preview: previewMediaURL, get: getMediaURL };

export default MediaURL;

// const consoleError = (error) => {
//     if (error.response) {
//         // The request was made and the server responded with a status code
//         // outside the range of 2xx
//         console.log('Error data:', error.response.data);
//         console.log('Error status:', error.response.status);
//         console.log('Error headers:', error.response.headers);
//     } else if (error.request) {
//         // The request was made but no response was received
//         console.log('Error request:', error.request);
//     } else {
//         // Something happened in setting up the request that triggered an Error
//         console.log('Error message:', error.message);
//     }
//     console.log('Error config:', error.config);
//     throw error;
// };
// const AlertError = (error) => {

//     if (error.response) {
//         console.error('Error data: ' + error.response.data + 'Error status: ' + error.response.status, 'error');
//         // console.error('Error headers: ' + JSON.stringify(error.response.headers), 'error');
//     } else if (error.request) {
//         console.error('Error request: ' + error.request, 'error');
//     } else {
//         console.error('Error message: ' + error.message, 'error');
//     }

//     console.error('Error config: ' + JSON.stringify(error.config), 'error');
//     throw error;
//     // if (error.response) {
//     //     console.log('Error data:', error.response.data);
//     //     console.log('Error status:', error.response.status);
//     //     console.log('Error headers:', error.response.headers);
//     // } else if (error.request) {

//     //     console.log('Error request:', error.request);
//     // } else {
//     //     console.log('Error message:', error.message);
//     // }
//     // console.log('Error config:', error.config);
//     // throw error;
// };

// const handleError = (error) => {
//     if (error.response) {
//         // The request was made and the server responded with a status code
//         // outside the range of 2xx
//         console.log('Error data:', error.response.data);
//         console.log('Error status:', error.response.status);
//         console.log('Error headers:', error.response.headers);
//     } else if (error.request) {
//         // The request was made but no response was received
//         console.log('Error request:', error.request);
//     } else {
//         // Something happened in setting up the request that triggered an Error
//         console.log('Error message:', error.message);
//     }
//     console.log('Error config:', error.config);
//     throw error;
// };

// const mediaUpload = async (formData, progressUpdate) => {
//     const config = {
//         headers: { 'Content-Type': 'multipart/form-data' },
//         onUploadProgress: (data) => {
//             progressUpdate(data.progress * 100);
//         },
//         maxContentLength: 20000000, // 20MB
//         maxBodyLength: 20000000, // 20MB
//     };
//     try {
//         const response = await axios.post(mediaURL, formData, config);
//         return response.data;
//     } catch (error) {
//         handleError(error);
//     }
// };

// const getMethodFun = async (endPoint) => {
//     try {
//         const response = await axios.get(`${URL}/${endPoint}`);
//         return response.data;
//     } catch (error) {
//         handleError(error);
//     }
// };

// const postMethodFun = async (formData, endPoint) => {
//     try {
//         const response = await axios.post(`${URL}/${endPoint}`, formData);
//         return response.data;
//     } catch (error) {
//         handleError(error);
//     }
// };

// export { mediaUpload, getMethodFun, postMethodFun };
